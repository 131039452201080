<template>
  <v-list nav>
    <v-list-item-group>
      <template>
        <v-list-item :to="{ name: 'Home' }">
          <v-list-item-action>
            <v-icon>keyboard_return</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $tc("pages.home") }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon>home</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-divider class="mb-2"></v-divider>
      </template>

      <v-list-item v-if="vueTags" :to="{ name: 'Tags' }">
        <v-list-item-action>
          <v-icon>label</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Tags</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="vueVeiculos" :to="{ name: 'Veiculos' }">
        <v-list-item-action>
          <v-icon>directions_car</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $tc("pages.veiculo", 2) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="vueClassificacoes" :to="{ name: 'Classificacoes' }">
        <v-list-item-action>
          <v-icon>bookmark</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{
            $tc("pages.classificacao", 2)
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="vueOrigens" :to="{ name: 'Origens' }">
        <v-list-item-action>
          <v-icon>person_pin_circle</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $tc("pages.origem", 2) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="vueCidades" :to="{ name: 'Cidades' }">
        <v-list-item-action>
          <v-icon>location_on</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $tc("pages.cidade", 2) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MenuConfiguracoes",
  computed: {
    ...mapGetters("Componentes", ["getAccess"]),
    vueTags() {
      return this.getAccess("vueTags", "menu");
    },
    vueVeiculos() {
      return this.getAccess("vueVeiculos", "menu");
    },
    vueClassificacoes() {
      return this.getAccess("vueClassificacoes", "menu");
    },
    vueOrigens() {
      return this.getAccess("vueOrigens", "menu");
    },
    vueCidades() {
      return this.getAccess("vueCidades", "menu");
    },
  },
};
</script>

<style></style>
